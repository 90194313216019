import React from "react";
import { Button, Card, Image, Modal, Icon, Grid } from "semantic-ui-react";

const WorkCardWithModal = ({ project }) => {
  return project?.title ? (
    <Modal
      centered
      size="medium"
      closeOnPortalMouseLeave
      trigger={
        <Card
          style={{
            textAlign: "left",
            padding: "10px",
            border: "1px solid black",
            borderRadius: "16px",
          }}
        >
          <img src={project.image} style={{ height: "11rem" }} />
          <Card.Content
            style={{
              padding: "12px 0 0 4px",
            }}
          >
            <Card.Header
              style={{
                textAlign: "left",
                textWrap: "balance",
                fontSize: "15px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {project.title}
            </Card.Header>
          </Card.Content>
        </Card>
      }
    >
      <Modal.Header>{project.title}</Modal.Header>
      <Modal.Content>
        <Grid doubling columns={2}>
          <Grid.Column width={7}>
            <Image src={project.image} />
          </Grid.Column>
          <Grid.Column width={9}>
            <Modal.Description>
              <p style={{ fontSize: "17px" }}>{project.description}</p>
            </Modal.Description>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon
          color="green"
          labelPosition="left"
          as="a"
          href={project.url}
          target="_blank"
        >
          <Icon name={project.platform_icon} />
          Check it out
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default WorkCardWithModal;
